import WithTracker from "components/Tracker";
import { lazy } from "react";

const HomePage = lazy(() => import("pages/HomePage"));

const Routes = [
  {
    key: "home",
    path: "/",
    element: (
      <WithTracker title={"home"}>
        <HomePage />
      </WithTracker>
    ),
    label: "首頁",
    display: false,
  },
  {
    key: "service",
    path: "#service",
    label: "服務項目",
    display: true,
  },
  {
    key: "about",
    path: "#about",
    label: "關於絡益",
    display: true,
  },
  {
    key: "license",
    path: "#license",
    label: "關於團隊｜認證師資",
    display: true,
  },

  {
    key: "class",
    path: "#class",
    label: "認證課程",
    display: true,
  },
  {
    key: "cooperator",
    path: "#cooperator",
    label: "認證加盟主",
    display: true,
  },
];

export default [...Routes];
