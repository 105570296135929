import { ConfigProvider } from "antd";
import { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ReactGA from "react-ga4";

import Loading from "components/Loading";
import PageNotFound from "components/PageNotFound";

import MenuProvider from "provider/Menu";

import theme from "utils/theme";
import { gaTrackerId } from "envConfig";
import { routes } from "./routes";
import "./App.css";

if (gaTrackerId) ReactGA.initialize(gaTrackerId, { titleCase: false });

function RenderRoutes() {
  return (
    <Routes>
      {routes.map((route) =>
        route.children ? (
          route.children.map((child) => (
            <Route key={child.key} {...child} element={child.element} />
          ))
        ) : (
          <Route key={route.key} {...route} element={route.element} />
        )
      )}
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}
function App() {
  return (
    <ConfigProvider theme={theme}>
      <Suspense fallback={<Loading />}>
        <BrowserRouter>
          <MenuProvider>
            <RenderRoutes />
          </MenuProvider>
        </BrowserRouter>
      </Suspense>
    </ConfigProvider>
  );
}

export default App;
