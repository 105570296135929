import { useNavigate } from "react-router-dom";
import { Result, Space } from "antd";

import { basename } from "envConfig";

function PageNotFound() {
  const navigate = useNavigate();
  const goHome = () => {
    navigate(basename + "/");
  };

  return (
    <Result
      status="404"
      title="404"
      subTitle="抱歉，您目前的權限無法觀看此頁面"
    />
  );
}
export default PageNotFound;
