import { Spin } from "antd";

import LoadingStyle from "./styled";
function Loading() {
  return (
    <LoadingStyle>
      <Spin />
    </LoadingStyle>
  );
}
export default Loading;
